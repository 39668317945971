import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import { Context } from '../components/Context'

const NotFoundPage = () => (
  <Context.Consumer>
		{({ lang }) => (
      <Layout>
        <div class="main-container">
          <main class="site-main">
            <div class="container-fluid no-left-padding no-right-padding page-content">
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-8">
                    <div class="error-block">
                      <span>404</span>
                      <h2>Oops! That page can’t be found</h2>
                      <p>Sorry, but the page you were looking for could not be found.</p>
                      <Link to={lang==='en' ? "/en/" : "/ar/"}>Back Home</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </Layout>
    )}
  </Context.Consumer>
)

export default NotFoundPage
